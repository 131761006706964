.water-meter-readings {
    max-width: 600px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-radius: 4px;
  }
  .form-group .react-datepicker-wrapper {
    width: 100%; /* Ajusta a largura do wrapper do DatePicker */
    
  }
  
  .form-group .react-datepicker__input-container input {
    width: 100%;
    padding: 0.75rem;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .insert-reading-button, .reset-button {
    margin-top: 1rem;
    padding: 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;

  }
  
  .insert-reading-button {
    background-color: #007bff;
    color: white;
    padding: 1rem;
  }
  
  .reset-button {
    background-color: #ccc;
    color: black;
  }

  .unit-reading label {
    flex: 1;
    font-size: 16px;
    margin-right: 10px;
  }
  
  .unit-reading input {
    flex: 2;
    width: 100%;
    padding: 0.75rem; /* Aumenta a altura dos inputs */
    font-size: 16px;  /* Aumenta o tamanho do texto */
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }