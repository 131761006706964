.label-with-tooltip {
    position: relative;
    display: inline-block;
  }
  
  .asterisk {
    color: red;
    margin-left: 5px;
  }
  
  .tooltip-icon {
    cursor: pointer;
    color: #007bff;
    margin-left: 5px;
    font-size: 14px;
  }
  
  .tooltip-content {
    visibility: hidden;
    width: 150px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Posiciona o tooltip acima do ícone */
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .tooltip-content img {
    max-width: 100%;
    height: auto;
  }
  
  .label-with-tooltip:hover .tooltip-content {
    visibility: visible;
  }