.water-consumption-card {
    background-color: #ffffff;
    border-radius: 10px;
    /* padding: 20px; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* text-align: center; */
    /* position: relative; */
    max-width: 400px; 
    border: 1px solid #EAECF4;
}

.water-consumption-card-title {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    text-align: center;
    background-color: #F8F9FC;
    padding: 16px;
    color: #4E73DF;
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1px solid #EAECF4; /* Linha separadora */
}

.water-consumption-card-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:space-around;
}

.wave-progress-container {
    position: relative;
    width: 160px; 
    height: 160px; 
    padding-bottom: 10px;
}

.consumption {
    font-size: 22px;
    font-weight: 600;
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #858796;
}

.percentage-display {
    font-size: 22px;
    color: #333;
    
}
