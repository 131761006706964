.insert-reading-page {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.reading-container {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.last-reading-info {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
}

.blue-text {
  color: #007bff;
  font-size: 16px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 20px;
}

.custom-datepicker-input {
  width: 100%;
  padding: 0.75rem;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.custom-input {
  width: 100%;
  padding: 0.75rem;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.asterisk {
  color: red;
}

.insert-reading-button {
  margin-top: 1rem;
  padding: 0.75rem 1.5rem;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.insert-reading-button:hover {
  background-color: #0056b3;
}
