.instructions-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

h2 {
    color: #3a75c4;
    font-size: 28px;
    font-weight: bold;
}

p {
    font-size: 16px;
    color: #4a4a4a;
}

.instruction-list ul {
    list-style-type: none;
    padding: 0;
}

.instruction-list li a {
    color: #3a75c4;
    font-size: 18px;
    text-decoration: none;
    margin-bottom: 5px;
    display: inline-block;
}

.instruction-list li a:hover {
    text-decoration: underline;
}

.instruction-section {
    margin-top: 30px;
}

.instruction-section h3 {
    color: #3a75c4;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.instruction-section p {
    font-size: 16px;
    color: #4a4a4a;
    margin-bottom: 5px;
}

.instruction-section strong {
    font-weight: bold;
}
