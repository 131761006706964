
.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  padding: 20px;
  background: linear-gradient(90deg, #6ec1dd, #09444d);
}

.login-form-container {
  background-color: white;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  width: 300px;
  text-align: center;
}


.logo {
  max-width: 200px;
  margin-bottom: 1rem;
}

form input {
  width: 100%;
  padding: 0.8rem;
  margin: 0.5rem 0;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
}

.login-button, .register-button {
  width: 100%;
  padding: 0.8rem;
  margin: 0.5rem 0;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.logo {
  width: 200px; /* Ajuste esse valor conforme necessário */
  height: auto; /* Mantém a proporção da imagem */
  
}

.login-button {
  background-color: #3b8d99;
  color: white;
}

.register-button {
  color: #3b8d99;
  border: 1px solid #3b8d99;
}

.register-button:hover {
  background-color: #3b8d99;
  color: white;
}

.forgot-password {
  font-size: 0.9rem;
  margin-top: 1rem;
  color: #333;
}

.recovery-link {
  color: #3b8d99;
  cursor: pointer;
  font-weight: bold;
}
