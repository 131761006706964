.reading-search-page {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .reading-container {
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 20px;
  }
  
  .dropdown {
    width: 100%;
    padding: 0.75rem;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .custom-datepicker-input {
    width: 100%;
    padding: 0.75rem;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .search-button {
    margin-top: 1rem;
    padding: 0.75rem 1.5rem;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .search-button:hover {
    background-color: #0056b3;
  }
  
  .error {
    color: red;
    margin-top: 10px;
  }
  
  .readings-table {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
  }
  
  .readings-table th,
  .readings-table td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ccc;
  }
  
  .readings-table th {
    background-color: #f4f4f4;
  }
  
  .no-results {
    margin-top: 20px;
    color: #555;
    text-align: center;
  }
  