.change-password-page {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
  }
  
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .change-password-container {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    font-size: 14px;
    display: block;
    margin-bottom: 5px;
    color: #333;
  }
  
  input {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  input:focus {
    outline: none;
    border-color: #007bff;
  }
  
  .password-hints {
    font-size: 12px;
    color: #888;
    margin-top: 10px;
    list-style-type: none;
    padding-left: 0;
  }
  
  .password-hints li {
    margin-bottom: 5px;
  }
  
  .error-message {
    color: red;
    margin-bottom: 10px;
  }
  
  .change-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .change-button:hover {
    opacity: 0.9;
  }
  