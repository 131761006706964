.link-generation-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .link-section {
    margin-bottom: 30px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    color: #007bff;
    margin-bottom: 10px;
  }
  
  p {
    margin: 10px 0;
    font-size: 14px;
    color: #333;
  }
  
  ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  ul li {
    margin: 5px 0;
  }
  
  .confirm-info-title {
    margin-top: 20px;
    font-weight: bold;
    color: #555;
  }
  
  .show-link-button {
    margin-top: 15px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .show-link-button:hover {
    background-color: #0056b3;
  }
  