/* components/SideBar/SideBar.css */

.sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(#09444d, #6ec1dd); /* Degrade vertical */
    width: 230px; 
    height: auto; 
    padding: 20px;
    box-sizing: border-box;
}


.sidebar-logo-link {
    width: 70%;
    cursor: pointer; 
}
.sidebar-logo {
    width: 100%; /* Largura total do elemento */
    /* cursor: pointer; Cursor como ponteiro para indicar clique */
    margin-bottom: 5px; /* Espaçamento abaixo do logo */
}

.sidebar-divider {
    background-color: #2E6067;
    height: 1px; /* Altura do divisor */
    width: 100%; /* Largura do divisor */
    margin: 10px; /* Margem vertical para espaçamento */
    margin-bottom: 10px;
}

.sidebar-items {
    display: flex;
    flex-direction: column; /* Para garantir que os itens estejam dispostos em uma coluna */
    align-items: flex-start; /* Alinhar os itens no início (à esquerda) */
    width: 190px;
}


.sidebar-item {
    font-family: 'Nunito', sans-serif; 
    font-weight: bold;
    font-size: 15px; 
    color: #c7c7c7; /* Cor mais escura padrão */
    background-color: rgba(0, 0, 0, 0); /* Fundo escurecido */
    padding: 10px 0;
    width: 100%;
    text-align: left;
    cursor: pointer; 
    display: flex; 
    align-items: center; 
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s; /* Transição suave */
    text-decoration: none;
}

.sidebar-item-icon {
    margin-right: 10px; /* Espaçamento entre ícone e texto */
}

/* Quando passar o mouse */
.sidebar-item:hover {
    color: #fff; /* Cor clara original */
    background-color: rgba(0, 0, 0, 0); /* Fundo claro original */
}

/* Estilos CSS para o botão e o ícone */
.sidebar-back-button {
    width: 35px; /* Largura fixa para tornar o botão redondo */
    height: 35px; /* Altura igual à largura para tornar o botão redondo */
    background-color: #549198; /* Cor de fundo do botão (pode ajustar conforme necessário) */
    border-radius: 50%; /* Arredondar o botão */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: background-color 0.1s ease; /* Efeito de transição suave */
    margin: 10px;
}

.icon {
    font-size: 40px;
    color: #447e85;
    cursor: pointer;
}



.sidebar-back-button:hover {
    background-color: #5dc1cd; /* Cor de fundo quando passar o mouse (pode ajustar conforme necessário) */
}

.arrow-icon {
    font-family: 'Nunito', sans-serif; 
    font-weight: bold;
    font-size: 20px; /* Tamanho do ícone (pode ajustar conforme necessário) */
    margin-right: 2px; /* Espaçamento entre o ícone e o texto (pode ajustar conforme necessário) */
}

.sidebar-subitems {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
}

.sidebar-subitem {
    font-family: 'Nunito', sans-serif;
    font-size: 14px;
    color: #fff;
    padding: 5px 0;
    cursor: pointer;
}

.sidebar-subitem:hover {
    color: #5dc1cd;
}

.sidebar-back-button {
    width: 35px;
    height: 35px;
    background-color: #549198;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.1s ease;
    margin: 10px;
}

.icon {
    font-size: 40px;
    color: #447e85;
    cursor: pointer;
}

.sidebar-back-button:hover {
    background-color: #5dc1cd;
}

.sidebar-item-icon {
    margin-right: 10px;
}

.sidebar-expand-icon {
    margin-left: auto; /* Isso posiciona a seta ao final, no lado direito */
}