.old-bills-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .last-update {
    font-size: 14px;
    color: #888;
    margin-bottom: 20px;
  }
  
  .old-bills-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .old-bills-table th, .old-bills-table td {
    border: 1px solid #444;
    padding: 12px;
    text-align: left;
  }
  
  .old-bills-table th {
    background-color: #4a4a4a;
    color: white;
  }
  
  .old-bills-table tr {
    background-color: #616161;
    color: white;
  }
  