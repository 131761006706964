/* Contêiner principal do layout */
.layout-container {
    display: flex; /* Sidebar ao lado de Main Area */
    min-height: 100vh; /* Garante que ocupe toda a altura da página */
    min-width: 100vw
}

/* Sidebar */
.layout-container > .sidebar {
    width: 250px; /* Largura fixa do Sidebar */
    color: white; /* Cor do texto */
    padding: 1rem;
    display: flex;
    flex-direction: column; /* Garante que os itens sejam empilhados verticalmente */
    flex-shrink: 0; /* Sidebar não diminui de tamanho */
}

/* Área Principal */
.main-area {
    display: flex;
    flex-direction: column; /* Navbar no topo, Página no meio, Footer no final */
    flex: 1; /* Ocupar o restante do espaço */
    background-color: #f5f5f598;
}

/* Navbar */
.main-area > nav {
    /* Navbar */
    color: white;
    padding: 0.5rem 1rem; /* Reduz a altura */
    height: 60px; /* Define uma altura fixa menor */
    text-align: center;
    display: flex;
    align-items: center; /* Centraliza verticalmente o conteúdo */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adiciona sombra sutil */
    z-index: 1; /* Garante que o Navbar esteja acima de outros elementos */
}

/* Conteúdo da Página */
.page-content {
    flex: 1; /* Ocupa todo o espaço restante entre o Navbar e o Footer */
    padding: 2rem;
    overflow-y: auto; /* Adiciona rolagem vertical, se necessário */
    background-color: white; /* Fundo branco para o conteúdo */
}

/* Footer */
.main-area > footer {
    flex-shrink: 0; /* Impede que o Footer redimensione */
    color: white; /* Cor do texto */
    text-align: center;
    padding: 1rem;
}
