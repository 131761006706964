.payment-history-page {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.status-box, .history-box {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.status {
  color: #4CAF50; /* Verde para indicar status */
}

.order-history-table {
  width: 100%;
  border-collapse: collapse;
}

.order-history-table th,
.order-history-table td {
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  text-align: left;
}

.error-message {
  background: #FFEDED;
  border: 1px solid #FF4D4F;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  color: #FF4D4F;
  box-shadow: 0px 4px 8px rgba(255, 77, 79, 0.2);
}

.error-message h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.error-message p {
  font-size: 16px;
}
