.consumption-history-page {
  padding: 20px;
  font-family: Arial, sans-serif;
}

h2 {
  color: #333;
  margin-bottom: 20px;
}

.consumption-history-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.consumption-history-table th,
.consumption-history-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.consumption-history-table th {
  background-color: #f4f4f4;
  color: #333;
}

.consumption-history-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.consumption-history-table tr:hover {
  background-color: #f1f1f1;
}

.edit-input {
  padding: 4px;
  width: 80px;
  font-size: 14px;
}

.edit-button,
.delete-button,
.save-button,
.cancel-button {
  padding: 6px 10px;
  margin: 0 4px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.edit-button {
  background-color: #4CAF50; /* Green */
  color: white;
}

.edit-button:hover {
  background-color: #45a049;
}

.delete-button {
  background-color: #f44336; /* Red */
  color: white;
}

.delete-button:hover {
  background-color: #da190b;
}

.save-button {
  background-color: #008CBA; /* Blue */
  color: white;
}

.save-button:hover {
  background-color: #007bb5;
}

.cancel-button {
  background-color: #aaa; /* Gray */
  color: white;
}

.cancel-button:hover {
  background-color: #888;
}
