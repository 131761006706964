.dropdown {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .select-wrapper {
    position: relative;
  }
  
  .select-wrapper select {
    width: 100%;
    padding: 0.75rem;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    appearance: none; /* Remove o estilo padrão do navegador */
  }
  
  .dropdown-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
  
  .asterisk {
    color: red;
  }
  
  .dropdown-label {
    font-size: 16px;
    margin-bottom: 5px;
  }