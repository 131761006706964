/* src/components/Card/Card.css */

.card {
    flex: 0 1 calc(20% - 0.8rem);
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    padding: 1rem;
    margin: 0.5rem;
    transition: transform 0.1s ease;
    border: 1px solid #EAECF4;
}

/* Quando a tela estiver com largura máxima de 800px, muda para 2 cards por linha */
@media (max-width: 1120px) {
    .card {
        flex-basis: 40%; /* Ocupa aproximadamente 50% da linha */
    }
}

/* Quando a tela estiver com largura máxima de 500px, muda para 1 card por linha */
@media (max-width: 830px) {
    .card {
        flex-basis: 100%; /* Ocupa 100% da linha */
    }
}

.card:hover {
    transform: scale(1.05);
}

.card-icon {
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    /* Ícone de tamanho e cor exemplo, ajuste conforme o ícone que você está usando */
    font-size: 2rem;
    color: #a6adb5;
    padding: 5px;
}

.card-details h3 {
    margin: 0;
    font-size: 0.8rem;
    /* font-weight: bold; */
    color: #333;
    font-weight: 500;
    padding-bottom: 10px;
}

.card-details p {
    margin: 0;
    font-size: 1.1rem;
    color: #4E73DF;
    font-weight: bold;
}

/* Classes adicionais para as cores das bordas e ícones */
.card.blue-border {
    border-left: 5px solid #4E73DF;
}

.card.green-border {
    border-left: 5px solid #28a745;
}

.card.orange-border {
    border-left: 5px solid #fd7e14;
}

.card.red-border {
    border-left: 5px solid #dc3545;
}

/* Ajuste o caminho para a fonte do ícone se estiver usando um framework como FontAwesome */
.card-icon i {
    font-size: 2em;
    margin-right: 0.5em;
}
