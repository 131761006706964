.consumption-table-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .search-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .search-section p {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .datepickers-container {
    display: flex;
    gap: 10px;
    align-items: flex-end;
  }
  
  .datepicker-group {
    display: flex;
    flex-direction: column;
  }
  
  .custom-datepicker {
    padding: 10px;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .search-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .search-button:hover {
    background-color: #0056b3;
  }
  
  .consumption-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .consumption-table th, .consumption-table td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
  }
  
  .consumption-table th {
    background-color: #f4f4f4;
  }
  
  .consumption-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  