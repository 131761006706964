.new-bills-page {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
}

h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.new-bills-table {
  width: 100%;
  border-collapse: collapse;
}

.new-bills-table th, .new-bills-table td {
  border: 1px solid #444;
  padding: 12px;
  text-align: left;
}

.new-bills-table th {
  background-color: #4a4a4a;
  color: white;
}

.new-bills-table tr {
  background-color: #616161;
  color: white;
}

.edit-button, .save-button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.save-button {
  background-color: #007bff;
}

.save-button:hover, .edit-button:hover {
  opacity: 0.8;
}

/* Definindo o tamanho dos campos de input */
input[type="text"], input[type="number"] {
  width: 100%; /* Faz com que o input ocupe o mesmo espaço da célula */
  padding: 10px;
  box-sizing: border-box; /* Garante que padding e borda não afetem o tamanho */
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

td input {
  background-color: #616161;
  color: white; /* Para manter a cor do texto similar ao modo de exibição */
}

td input:focus {
  outline: none; /* Remove a borda de foco padrão */
  border-color: #007bff; /* Adiciona uma cor de borda ao focar */
}


.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container input {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  background-color: #616161;
  color: white;
}

.react-datepicker__input-container input:focus {
  outline: none;
  border-color: #007bff;
}
