.payment-instructions-page {
    max-width: 700px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    color: #007bff;
    margin-bottom: 20px;
  }
  
  .payment-details h3 {
    color: #007bff;
    margin-top: 20px;
  }
  
  .payment-details p {
    margin: 5px 0;
    font-size: 14px;
  }
  
  .instructions {
    font-size: 14px;
    margin-top: 20px;
  }
  
  .contact-details p {
    font-size: 14px;
    margin: 5px 0;
  }
  
  .contact-details i {
    margin-right: 8px;
  }
  