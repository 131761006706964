.edit-email-page {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
  }
  
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .edit-email-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .edit-email-table th, .edit-email-table td {
    border: 1px solid #444;
    padding: 12px;
    text-align: left;
  }
  
  .edit-email-table th {
    background-color: #4a4a4a;
    color: white;
  }
  
  .edit-email-table tr {
    background-color: #616161;
    color: white;
  }
  
  .edit-button, .save-button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .save-button {
    background-color: #007bff;
  }
  
  .save-button:hover, .edit-button:hover {
    opacity: 0.8;
  }
  
  input[type="email"] {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    background-color: #616161;
    color: white;
  }
  
  input[type="email"]:focus {
    outline: none;
    border-color: #007bff;
  }
  