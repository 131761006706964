.address-page {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    background-color: #f9fafb;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    font-size: 24px;
    color: #3b82f6;
    margin-bottom: 20px;
    text-align: left;
  }
  
  .address-form label {
    display: block;
    font-size: 14px;
    color: #374151;
    margin-bottom: 8px;
  }
  
  .address-form input,
  .address-form select {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #d1d5db;
    border-radius: 4px;
  }
  
  button {
    width: 100%;
    padding: 10px;
    background-color: #3b82f6;
    color: white;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:disabled {
    background-color: #9ca3af;
  }
  
  .error-message {
    color: #ef4444;
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
  }
  
  .success-message {
    color: #10b981;
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
  }
  