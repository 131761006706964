.file-upload {
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: center;
    cursor: pointer;
  }
  
  .file-upload.dragging {
    border-color: #00CED1; /* Verde água (Aqua) quando arrastando */
    background-color: #f0f8ff;
  }
  
  .preview-container {
    margin-top: 20px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
  
  .preview-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }