.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    width: 100%;
}

.navbar-logo img {
    width: 100px;
    height: auto;
    cursor: pointer;
}

.navbar-links {
    display: flex;
    gap: 1rem;
}


.navbar-button {
    display: flex; /* Coloca os botões lado a lado */
    gap: 1rem; /* Define o espaçamento entre os botões */
    align-items: center; /* Centraliza verticalmente os botões */
}

/* Estilo do botão de Login */
.nav-button.login {
    background-color: transparent;
    color: #333;
    border: none;
}

/* Estilo do botão de Cadastro */
.nav-button.register {
    background-color: #3b8d99;
    color: white;
    border: 1px solid #3b8d99;
    padding: 0.4rem 1rem;
    border-radius: 6px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.nav-button.register:hover {
    background-color: #2c6c75;
    color: white;
}

