/* Página externa */
.page-container {
    background: linear-gradient(135deg, #00c4cc, #004e68);
    min-height: 100vh;
    min-width: 100vw;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Arial', sans-serif;
    padding: 2rem;
}

/* Caixa branca no centro */
.form-box {
    width: 100%;
    max-width: 400px;
    background-color: white;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
    text-align: center;
}

/* Títulos */
.form-box h2 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    color: #014e68;
    font-weight: bold;
}

/* Inputs */
.form-box input {
    width: 100%;
    padding: 0.75rem;
    margin: 0.5rem 0;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 1rem;
    background-color: #f5faff;
    box-sizing: border-box;
}

/* Botão */
.form-box button {
    width: 100%;
    padding: 0.75rem;
    background-color: #014e68;
    color: white;
    border: none;
    border-radius: 8px;
    font-weight: bold;
    margin-top: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.form-box button:hover {
    background-color: #01678a;
}

.form-box input[type="email"] {
    color: black;
}
/* Mensagens de erro e sucesso */
.error-message {
    color: #ff4d4f;
    font-size: 0.875rem;
    margin-top: 0.5rem;
}

.success-message {
    color: #28a745;
    font-size: 0.875rem;
    margin-top: 0.5rem;
}

/* Links pequenos */
.form-box small {
    font-size: 0.8rem;
    color: #555;
    display: block;
    margin-top: 1rem;
}

