/* Página externa */
.page-container {
    background: linear-gradient(135deg, #00c4cc, #004e68);
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
}

/* Caixa branca no centro */
.form-box {
    width: 100%;
    max-width: 400px;
    background-color: white;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
}

/* Container de cada label + input */
.form-group {
    display: flex;
    align-items: center;
    margin: 0.5rem 0;
}

/* Label */
.form-group label {
    width: 120px; /* Define largura fixa para alinhamento consistente */
    margin-right: 1rem; /* Espaço entre o label e o input */
    font-size: 1rem;
    color: #333;
    text-align: right; /* Alinha o texto do label à direita */
}

/* Inputs */
.form-group input {
    flex: 1;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    background-color: #f9f9f9;
}

/* Botão */
button[type="submit"] {
    width: 100%;
    padding: 0.75rem;
    background-color: #014e68;
    color: white;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    margin-top: 1rem;
    cursor: pointer;
}

button[type="submit"]:hover {
    background-color: #01678a;
}

.form-group select {
    flex: 1;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    background-color: #f9f9f9;
    color: #333;
}

/* Mensagens de erro e sucesso */
.error-message {
    color: #ff4d4f;
    font-size: 0.9rem;
    margin-top: 0.5rem;
}

.success-message {
    color: #28a745;
    font-size: 0.9rem;
    margin-top: 0.5rem;
}

/* Inputs e selects */
input, select, textarea {
    width: 100%; /* Garante que todos tenham a mesma largura */
    max-width: 100%; /* Limita a largura máxima */
    min-width: 250px; /* Define largura mínima */
    padding: 0.75rem; /* Mesma altura interna para todos */
    margin: 0.5rem 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    background-color: #f9f9f9;
    color: #333;
    box-sizing: border-box; /* Garante que padding e border não influenciem no tamanho */
}

/* Botões */
button {
    width: 100%; /* Mesmo comportamento de largura */
    max-width: 100%;
    padding: 0.75rem;
    background-color: #014e68;
    color: white;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    margin-top: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
