/* src/components/Footer/Footer.css */

.footer {
    width: 100%;
    padding: 2rem 0;

    background: linear-gradient(90deg,  #6ec1dd, #09444d);
    text-align: center;
    color:azure
}
.footer .container {
    text-align: center;
    padding: 1rem 0;
}

.footer-content {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
}

.footer-section {
    flex: 1;
    margin: 1rem;
    max-width: 200px;
}

.footer-logo {
    max-width: 100px;
}

.footer-bottom {
    margin-top: 1rem;
    font-size: 0.8rem;

}
