.upload-page {
    max-width: 600px;
    margin: 0 auto;
    padding: 1rem;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .file-upload-container {
    margin-top: 2rem; /* Ajuste o valor conforme o espaçamento desejado */
  }
  
  .upload-button {
    background-color: #007bff;
    color: white;
    padding: 0.75rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .upload-button:disabled {
    background-color: #ccc;
  }